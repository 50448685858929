<template>
    <section>
        <div class="row mx-0 mt-3 py-2 bg-white br-t-8">
            <div class="col-3 d-middle  tres-puntos">
                <p class="text-general tres-puntos f-18 f-600">
                    Filtra por <br /> estado
                </p>
            </div>
            <div class="col px-0 d-middle">
                <el-checkbox v-model="sin_confirmar" class="mx-2 check-yellow" />
                <div :class="sin_confirmar? 'bg-yellow text-white' : 'bg-light-f5 border text-general2'" class="  px-2 br-5 tres-puntos cr-pointer height-box" @click="sin_confirmar = !sin_confirmar">
                    <i class="icon-receipt f-20" />
                    <span class="f-15">
                        Sin Confirmar ({{ contarPorEstado(1) }})
                    </span>
                </div>
            </div>
            <div class="col px-0 d-middle">
                <el-checkbox v-model="confirmado" class="mx-2 check-blue" />
                <div :class="confirmado ? 'bg-blue text-white' : 'bg-light-f5 border text-general2'" class="  px-2 br-5 tres-puntos cr-pointer height-box" @click="confirmado = !confirmado">
                    <i class="icon-ok-circled f-20" />
                    <span class="f-15">
                        Confirmado ({{ contarPorEstado(2) }})
                    </span>
                </div>
            </div>
            <div class="col px-0 d-middle">
                <el-checkbox v-model="alistando" class="mx-2 check-pink" />
                <div :class="alistando ? 'bg-pink text-white' : 'bg-light-f5 border text-general2'" class=" px-2 br-5 tres-puntos cr-pointer height-box" @click="alistando = !alistando">
                    <i class="icon-cart-arrow-down f-20" />
                    <span class="f-15">
                        Alistando ({{ contarPorEstado(201) }})
                    </span>
                </div>
            </div>
            <div class="col px-0 d-middle">
                <el-checkbox v-model="empacado" class="mx-2 check-purple" />
                <div :class="empacado ? 'bg-purple text-white' : 'bg-light-f5 border text-general2' " class="  px-2 br-5 tres-puntos cr-pointer height-box" @click="empacado = !empacado">
                    <i class="icon-shopping-outline f-20" />
                    <span class="f-15">
                        Empacado ({{ contarPorEstado(202) }})
                    </span>
                </div>
            </div>
            <!-- <div class="col px-0 d-middle">
                <el-checkbox v-model="enviado" class="mx-2 check-green" />
                <div :class="enviado? 'bg-green text-white' : 'bg-light-f5 border text-general2'" class=" px-2 br-5 tres-puntos cr-pointer height-box" @click="enviado = !enviado">
                    <i class="icon-truck f-20" />
                    <span class="f-15">
                        Enviado ({{ contarPorEstado(3) }})
                    </span>
                </div>
            </div> -->
        </div>
        <div class="custom-scroll_tablero pt-2 bg-white overflow-auto" style="height:calc(100vh - 375px)">
            <component :is="tabla" :key="key" :pedidos="datosFiltradosPorEstado" @listar="init()" />
        </div>
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import moment from 'moment'

import tablero from '~/services/routing/tablero'

const filtrado = (datos, todosCedis = false, cb) => {
    let datosCopy = _.cloneDeep(datos)
    return datosCopy.filter(el => {
        if(!el.horarios.length) return true
        const p = el.horarios.filter(elm => {
            if(!elm.pedidos.length) return true
            const j = elm.pedidos.filter(ele => {
                return cb(ele)
            })
            elm.pedidos = _.orderBy(j, 'id')
            return elm.pedidos.length > 0
        })
        el.horarios = todosCedis ? p : el.horarios
        return el.horarios.length > 0
    })
}


export default {
    name: 'FranjasNoAsignados',
    components: {
        columnLate: () => import('./columnLate'),
        column : () => import('./column')
    },
    props: {
        filtro: {
            type: Object,
            default: () => ({})
        },
        actualizar: {
            type: Boolean,
            default: () => false
        },
    },
    data(){
        return {
            sin_confirmar: true,
            confirmado: true,
            alistando: true,
            empacado: true,
            // enviado: true,
            datos: [],
            estados: [],
            datosFiltradosPorEstado: [],
            info_filtros: {},
            key: true,
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            cedisNavbar:'cedis/cedisNavbar',
        }),
        tabla(){
            return this.multipleCedis ? 'columnLate' : 'column'
        },
        multipleCedis(){
            return this.cedisNavbar.length == 0 || this.cedisNavbar.length > 1
        }
    },
    watch: {
        async id_cedis(val){
            await this.init()
        },
        filtro: {
            deep: true,
            async handler(val){
                await this.init()
            }
        },
        actualizar: {
            deep: true,
            async handler(val){
                await this.init()
            }
        },
    },
    async mounted(){
        this.$watch(vm => [vm.sin_confirmar, vm.confirmado, vm.alistando, vm.empacado, /* vm.enviado */], val => {
            this.setEstados()
            this.filtrarPorEstado()
        })
        await this.init()
    },
    methods: {
        async init(){
            await this.getData()
            this.setEstados()
            this.filtrarPorEstado()
        },
        async getData(){
            try {
                const { data } = this.multipleCedis ? await tablero.getDataNoAsignado(this.filtro) : await tablero.porCedisNoAsignado(this.filtro);
                this.$nextTick(function(){
                    this.datos = data.pedidos
                    this.key = !this.key
                })
            } catch (error){
                this.error_catch(error)
            }
        },
        setEstados(){
            const estados = []
            this.sin_confirmar ? estados.push('1') : null
            this.confirmado ? estados.push('2') : null
            this.alistando ? estados.push('201') : null
            this.empacado ? estados.push('202') : null
            // this.enviado ? estados.push('3') : null
            this.estados = estados
        },
        contarPorEstado(estado){
            let count = 0
            if(!this.datosFiltradosPorEstado.length) return count
            this.datosFiltradosPorEstado.forEach(el => {
                el.horarios.forEach(elm => {
                    const pedidos = elm.pedidos.filter(ped => ped.estado == estado).length
                    count += pedidos
                })
            })
            return count
        },
        filtrarPorEstado(){
            this.datosFiltradosPorEstado = filtrado(this.datos, this.multipleCedis, (el) => {
                return this.estados.includes(el.estado.toString())
            })

            let pedidosFechas = this.datosFiltradosPorEstado.reduce((acc, el) => {

                let ids = _.flatten(el.horarios.map(ele => ele.pedidos.map(({id}) => id)))

                acc.fechas.push(el.fecha)
                acc.pedidos = [...acc.pedidos, ...ids]

                return acc
            }, {
                fechas: [],
                pedidos: [],
            })
            this.$store.commit('routing/tablero/setPedidosFechas', pedidosFechas)
        },
    }
}
</script>

<style lang="scss" scoped>
.bg-yellow{ background-color: #FF9D32; }
.text-yellow{ color: #FF9D32; }
.bg-blue{ background-color: #0892DD; }
.text-blue{ color: #0892DD;}
.bg-pink{ background-color: #D6017B; }
.text-pink{ color: #D6017B;}
.bg-purple{ background-color: #6D3CE1; }
.text-purple{ color: #6D3CE1; }
.bg-green{ background-color: #28D07B;}
.text-green{ color: #28D07B; }
.custom-scroll_tablero::-webkit-scrollbar-track{
    height: 6px;
    width:12px;
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.1); background-color:#F5F5F5; border-radius: 5px; }

.custom-scroll_tablero::-webkit-scrollbar{
    background-color: #DBDBDB;
    width:8px;
    height: 18px;
    border-radius: 5px;
}
.custom-scroll_tablero::-webkit-scrollbar-thumb{
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.1);
     background:var(--text-general);
     border-radius: 5px;
}
.height-box{
    height: 40px;
    align-items: center;
    display: flex;
}
</style>
